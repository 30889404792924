const initialState = {
    ratingObj: {},
    scoresToUpdate: {}    
}
  
  const ratingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_RATING': 
        const newRatingState = {}
        const newRating = action.payload
        const ratingFromGet = newRating.ratingFrom
        const ratingToGet = newRating.ratingTo

        newRatingState[ratingFromGet] = state.ratingObj[ratingFromGet] || {}
        newRatingState[ratingFromGet][ratingToGet] = newRating


        return {
          ...state,
          ratingObj: newRatingState
        }
      case 'GET_RATING_FAIL':
        return {
          ...state,
          ratingObj: {}
        }
      case 'UPDATE_SCORE':
        const oldState = state.scoresToUpdate
        const { score, ratingFrom, ratingTo } = action.payload

        oldState[action.payload.ratingTo] = {
          score,
          ratingFrom,
          ratingTo
        }

        return {
          ...state,
          scoresToUpdate: oldState
      }

      case 'BULK_UPDATE_SCORE': 
        return {
          ...state,
          scoresToUpdate: {}
      }

      case 'UPDATE_SCORE_FAIL':
        return {
          ...state,
          updatedScore: {}
      }
      default:
        return { ...state }
    }
  }
  export default ratingsReducer
  