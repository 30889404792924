const initialState = {
    allRatingHistory: [],
    isGetallRatingHistoryLoading: false,
    isGetallRatingHistoryError: false
  }
  
  const RatingReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_RATING_HISTORY':
        return {
          ...state,
          isGetallRatingHistoryLoading: false,
          isGetallRatingHistoryError: false,
          allRatingHistory: action.payload
      }
      case 'GET_RATING_HISTORY_LOADING':
        return {
          ...state,
          isGetallRatingHistoryLoading: true
      }
      case 'GET_RATING_HISTORY_ERROR':
        return {
          ...state,
          allRatingHistory: [],
          isGetallRatingHistoryLoading: false,
          isGetallRatingHistoryError: "error while getting ratings history"
      }
      default:
        return { ...state }
    }
  }
  export default RatingReducer
  